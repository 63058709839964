import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  connect() {
    this.lazyValue ? this.lazyAnimate() : this.animate();
  }
  animate() {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp)
        startTimestamp = timestamp;
      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / this.durationValue, 1);
      this.element.innerHTML = Math.floor(progress * (this.endValue - this.startValue) + this.startValue).toString();
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }
  lazyAnimate() {
    const observer = new IntersectionObserver((entries, observer2) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.animate();
          observer2.unobserve(entry.target);
        }
      });
    }, this.lazyAnimateOptions);
    observer.observe(this.element);
  }
  get lazyAnimateOptions() {
    return {
      threshold: this.lazyThresholdValue,
      rootMargin: this.lazyRootMarginValue
    };
  }
}
src_default.values = {
  start: Number,
  end: Number,
  duration: Number,
  lazyThreshold: Number,
  lazyRootMargin: {
    type: String,
    default: "0px"
  },
  lazy: Boolean
};
export { src_default as default };
